import { Box, Button, Card, Chip, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingData from "../../../components/LoadingData";
import { ACOMPANHAMENTO_CALENDARIO } from "../../../config/HashRouter";
import CalendarioServices from "../../calendario/CalendarioService";
import CalendarioAcompanhamentoServices from "../CalendarioAcompanhamentoServices";
import AbortController from "./AbortController";
import { alteraModelo } from "../reducer";
import {
  alteraModeloSelecionado,
  atualizaDescritoresCalendario,
  atualizaDescritoresLegenda,
  atualizaFluxoStatus,
} from "../../calendario/reducer/calendario";
import PainelCalendario from "../../calendario/components/PainelCalendario";
import { verificaUsuarioAcessoModeloTrimestre } from "../../calendario/CalendarioHelper";
import Utils from "../../../utils/Uitls";

const PainelVisualizacaoCalendario = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nomeTurno, setNomeTurno] = useState("");

  const { modelo } = useSelector((state) => state.acompanhamento);
  const { anoSelecionado, user } = useSelector((state) => state.global);

  const { descritores, descritoresLegendas, fluxoStatus, turnoSelecionado } = useSelector(
    (state) => state.calendarioLetivo
  );
  let searchParams = new URLSearchParams(search);

  useEffect(() => {
    let modelo_id = searchParams.get("id");
    if (modelo_id) {
      CalendarioAcompanhamentoServices.buscaDadosCalendarioById(dispatch, { id: modelo_id });
    }
  }, []);

  useEffect(() => {
    if (!fluxoStatus) {
      CalendarioServices.buscaFluxoStatus(dispatch);
    }
  }, [fluxoStatus]);

  useEffect(() => {
    setNomeTurno(CalendarioServices.getNameTurno(turnoSelecionado));
  }, []);

  useEffect(() => {
    if (modelo?.dados) {
      dispatch(alteraModeloSelecionado(modelo.dados.id));
      let params = { calendario_modelo_id: modelo.dados.id };
      if (verificaUsuarioAcessoModeloTrimestre(user, anoSelecionado)) {
        params.modelo_trimestre = true;
      }
      CalendarioServices.buscaDescritoresCalendario(dispatch, params);
    }
  }, [modelo?.dados]);

  useEffect(() => {
    if (modelo?.dados && anoSelecionado) {
      CalendarioServices.buscaDescritoresLegenda(dispatch, {
        empresa_id: modelo.dados.empresa_id,
        ano: anoSelecionado,
      });
    }
  }, [anoSelecionado, modelo?.dados]);

  useEffect(() => {
    return () => {
      dispatch(alteraModelo(null));
      dispatch(atualizaDescritoresCalendario(null));
      dispatch(atualizaDescritoresLegenda(null));
      dispatch(atualizaFluxoStatus(null));
      dispatch(alteraModeloSelecionado(null));
    };
  }, []);
  const existeListaFluxoStatusCalendario =
    fluxoStatus && fluxoStatus.dados && fluxoStatus.dados.length > 0 ? true : false;
  const ButtonVoltar = () => {
    return (
      <Box sx={{ marginRight: 2 }}>
        <Tooltip title="Voltar para a listagem">
          <Button
            variant="contained"
            sx={{ minWidth: "40px", maxWidth: "40px", minHeight: "40px", maxHeight: "40px", padding: "0px 0px" }}
            onClick={() => navigate(ACOMPANHAMENTO_CALENDARIO)}
          >
            <MdKeyboardArrowLeft size={30} />
          </Button>
        </Tooltip>
      </Box>
    );
  };

  return (
    <>
      <AbortController />
      <Grid container style={{ width: "100%" }}>
        <Grid item xs={12} style={{ margin: "0px" }}>
          {modelo && <LoadingData obj={modelo} msg={"Buscando dados"} />}
          {descritores && <LoadingData obj={descritores} msg={"Buscando descritores"} />}
          {descritoresLegendas && <LoadingData obj={descritoresLegendas} msg={"Buscando legendas"} />}
          {fluxoStatus && <LoadingData obj={fluxoStatus} msg={"Buscando fluxo de aleração"} />}
        </Grid>
        <Grid item xs={12} style={{ margin: "0px 0px 20px 0px", position: "sticky", top: 60, zIndex: 100 }}>
          {modelo && modelo.dados && (
            <Card style={{ padding: 10 }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ButtonVoltar />
                  <div>
                    <Typography variant="h6" style={{ fontWeight: 800 }}>
                      {modelo.dados.escola.nome}
                    </Typography>
                    {modelo?.dados?.escola?.inep && (
                      <Typography variant="subtitle2" style={{ marginTop: -5, color: "#666" }}>
                        INEP: {modelo.dados.escola.inep} 
                        { Utils.is_semec(user) && modelo?.dados?.escola?.zona?.descricao && ' - ZONA: ' + modelo.dados.escola.zona.descricao }
                      </Typography>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  {!!modelo.dados.manha && <Chip style={{ fontSize: 14, marginRight: 4 }} label={"MANHÃ"} />}
                  {!!modelo.dados.tarde && <Chip style={{ fontSize: 14, marginRight: 4 }} label={"TARDE"} />}
                  {!!modelo.dados.noite && <Chip style={{ fontSize: 14, marginRight: 4 }} label={"NOITE"} />}
                  {!!modelo.dados.integral && <Chip style={{ fontSize: 14 }} label={"INTEGRAL"} />}
                </div>
              </div>
            </Card>
          )}
        </Grid>
        {existeListaFluxoStatusCalendario && descritores && descritores.dados && <PainelCalendario />}
      </Grid>
    </>
  );
};

export default PainelVisualizacaoCalendario;
