import React, { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  TextField,
  FormControl,
  Button,
  Select,
  InputLabel,
  Card,
  Typography,
  Alert,
} from "@mui/material";
import Header from "../../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import CalendarioServices from "../CalendarioService";
import LoadingData from "../../../components/LoadingData";
import ModalModeloFormulario from "./modal/ModalModeloFormulario";
import { FaPlus } from "react-icons/fa";
import {
  alteraModeloSelecionado,
  alteraTurnoSelecionado,
  atualizaDescritoresCalendario,
  atualizaDescritoresLegenda,
  atualizaFluxoStatus,
  atualizaModelosCalendario,
  atualizaModelosParaImportar,
} from "../reducer/calendario";
import PainelCalendario from "./PainelCalendario";
import ModalImportacao from "./modal/ModalImportacao";
import { ROLES } from "../../../global/constants";
import AbortController from "./AbortController";
import { atualizaAnoSelecionadoGlobal } from "../../../global/reducer";
import HeaderServices from "../../../components/header/HeaderServices";
import "../styles/calendario.css";
import { verificaUsuarioAcessoModeloTrimestre } from "../CalendarioHelper";
// const anos = [2019, 2020, 2021, 2022, 2023];

const Painel = () => {
  const { descritores, modeloSelecionado, modelos, turnos, turnoSelecionado, fluxoStatus, modelosParaImportar } =
    useSelector((state) => state.calendarioLetivo);

  const { anoSelecionado, anos, user, modelo_turnos } = useSelector((state) => state.global);

  const dispatch = useDispatch();

  const [modalImportacao, setModalImportacao] = useState(false);
  const [modalNovoModelo, setModalNovoModelo] = useState(false);
  const [turnosDisponiveis, setTurnosDisponiveis] = useState([]);
  const [isModeloUnico, setIsModeloUnico] = useState();
  const [permiteAlteracaoViaAnoSelecionado, setPermiteAlteracaoViaAnoSelecionado] = useState();

  useEffect(() => {
    if (anos?.dados?.length && anoSelecionado !== null) {
      setPermiteAlteracaoViaAnoSelecionado(anos.dados.find((a) => a.ano === anoSelecionado)?.status === 1);
    }
  }, [anos?.dados, anoSelecionado]);

  useEffect(() => {
    if (modelo_turnos && anoSelecionado) {
      setIsModeloUnico(!modelo_turnos.includes(anoSelecionado));
    }
  }, [modelo_turnos, anoSelecionado]);

  useEffect(() => {
    if (user && !anoSelecionado) {
      dispatch(atualizaAnoSelecionadoGlobal(user.ano_base));
    }
  }, [user]);

  useEffect(() => {
    if (!anos) {
      HeaderServices.buscaListaAnos(dispatch, {}, true);
    }
  }, [anos]);

  useEffect(() => {
    if (!fluxoStatus) {
      CalendarioServices.buscaFluxoStatus(dispatch);
    }
  }, [fluxoStatus]);

  useEffect(() => {
    //busca descritores all p/ preencher legenda, forms e infos do calendário
    if (anoSelecionado) {
      CalendarioServices.buscaDescritoresLegenda(dispatch, { empresa_id: user.empresa.id, ano: anoSelecionado });
    }
  }, [anoSelecionado]);

  useEffect(() => {
    if (user.perfil === ROLES.PERFIL_ESCOLA && anoSelecionado) {
      //lista todos os turnos da escola logada
      CalendarioServices.buscaTurnosCalendario(dispatch, { ano: anoSelecionado, escola_id: user.relacionado.id });
      CalendarioServices.buscaModelosParaImportar(dispatch, { ano: anoSelecionado, somente_rede: 1 });
    }

    if (anoSelecionado) {
      //busca calendários
      CalendarioServices.buscaModelosCalendario(dispatch, {
        ano: anoSelecionado,
        naoSeleciona: ROLES.PERFIL_ESCOLA === user.perfil,
      });
    }
  }, [anoSelecionado]);

  useEffect(() => {
    if (modeloSelecionado) {
      let params = { calendario_modelo_id: modeloSelecionado }
      if (verificaUsuarioAcessoModeloTrimestre(user, anoSelecionado)) {
        params.modelo_trimestre = true;
      }
      //busca descritores do modelo selecionado p/ preencher o calendário
      CalendarioServices.buscaDescritoresCalendario(dispatch, params);
    } else {
      dispatch(atualizaDescritoresCalendario(null));
    }
  }, [modeloSelecionado]);

  useEffect(() => {
    if (turnoSelecionado && modelos && modelos.dados && modelos.dados.length > 0) {
      let modelo;
      if (isModeloUnico) {
        modelo = modelos.dados[0];
      } else {
        modelo = modelos.dados.find((modelo) => modelo[CalendarioServices.getNameTurno(turnoSelecionado)] === 1);
      }
      dispatch(alteraModeloSelecionado(modelo?.id));
    }
  }, [turnoSelecionado, modelos?.dados, isModeloUnico]);

  useEffect(() => {
    if (turnos?.dados && modelos?.dados) {
      if (isModeloUnico) {
        verificaAtualizaModelosSemTurnos(turnos.dados, modelos.dados);
      } else {
        let turnosFiltrados = turnos.dados.filter(
          (turno) => !modelos.dados.some((modelo) => modelo[CalendarioServices.getNameTurno(turno.sigla)] === 1)
        );
        setTurnosDisponiveis(turnosFiltrados);
      }
    }
  }, [turnos?.dados, modelos?.dados, isModeloUnico]);

  useEffect(() => {
    // IF adicionado para corrigir o caso de quando troca de ano para um ano que ainda não tem modelo cadastrado
    if (anoSelecionado) {
      dispatch(atualizaDescritoresCalendario(null));
      dispatch(atualizaDescritoresLegenda(null));
      dispatch(atualizaFluxoStatus(null));
      dispatch(alteraModeloSelecionado(null));
      dispatch(atualizaModelosCalendario(null));
    }

    return () => {
      dispatch(atualizaDescritoresCalendario(null));
      dispatch(atualizaDescritoresLegenda(null));
      dispatch(atualizaFluxoStatus(null));
      dispatch(alteraModeloSelecionado(null));
      dispatch(atualizaModelosCalendario(null));
    };
  }, [anoSelecionado]);

  const RenderMensagemListaVazia = ({ msg, txtButton, action }) => {
    return (
      <Card style={{ padding: 16, width: "100%", marginTop: 10 }}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <p style={{ marginBottom: 10 }}>{msg}</p>
          </Grid>
          {action && (
            <Grid item xs={12} md={2.5}>
              <Button variant="contained" onClick={action}>
                <Typography fontSize={14} noWrap>
                  {txtButton}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
    );
  };

  const renderPainelOuMensagem = () => {
    const existeListaFluxoStatusCalendario =
      (fluxoStatus && fluxoStatus.dados && fluxoStatus.dados.length > 0) || user.perfil === ROLES.PERFIL_GESTOR
        ? true
        : false;

    if (!!modeloSelecionado && existeListaFluxoStatusCalendario) {
      return <PainelCalendario />;
    }

    if (!modelos || modelos?.loading) {
      return null;
    }

    if (user.perfil === ROLES.PERFIL_GESTOR) {
      return (
        <RenderMensagemListaVazia
          action={!permiteAlteracaoViaAnoSelecionado ? null : () => setModalNovoModelo(true)}
          msg={`Esta rede ainda não possui nenhum ${isModeloUnico ? "calendário" : "modelo"} adicionado para este ano!`}
          txtButton={"Adicionar novo modelo"}
        />
      );
    }

    if (user.perfil === ROLES.PERFIL_ESCOLA) {
      if (modelosParaImportar?.dados?.length === 0) {
        return (
          <Alert style={{ width: "100%" }} severity="warning">
            Nenhum modelo adicionado pela rede!
          </Alert>
        );
      }
      if (turnosDisponiveis.length === 0 && !isModeloUnico) {
        return (
          <Alert style={{ width: "100%" }} severity="warning">
            Nenhum turno disponível para importação
          </Alert>
        );
      }
      if (modelosParaImportar?.dados?.length > 0) {
        return (
          <RenderMensagemListaVazia
            action={!permiteAlteracaoViaAnoSelecionado ? null : () => setModalImportacao(true)}
            msg={`Calendário não importado para este ano!`}
            txtButton={"Importar novo calendário"}
          />
        );
      }
    }

    return null;
  };

  const verificaAtualizaModelosSemTurnos = (turnosLista, modelosLista) => {
    if (modelosLista.length > 0 && turnosLista.length > 0) {
      let modelosSemTurnos = modelosLista.filter(
        (m) => m.integral === 0 && m.manha === 0 && m.noite === 0 && m.tarde === 0
      );
      if (modelosSemTurnos.length > 0) {
        let turnos = turnosLista.reduce((value, current) => {
          value[`turno_${CalendarioServices.getNameTurno(current.sigla)}`] = 1;
          return value;
        }, {});

        modelosSemTurnos.forEach((modelo) => {
          CalendarioServices.caadastrarNovoModelo(
            dispatch,
            {
              id: modelo.id,
              modelo: modelo.modelo,
              ano: anoSelecionado,
              ...turnos,
            },
            () => null,
            () => null,
            false
          );
        });
      }
    }
  };

  const alteraAnoSelecionadoCalendario = (val) => {
    dispatch(atualizaAnoSelecionadoGlobal(val.target.value));
    dispatch(atualizaModelosParaImportar(null));
  };

  const getAlertTurnosDisponiveis = () => {
    if (user.perfil !== ROLES.PERFIL_ESCOLA) {
      return null;
    }

    if (turnosDisponiveis.length === 0) {
      return null;
    }

    if (modelos?.loading) {
      return null;
    }

    if (!permiteAlteracaoViaAnoSelecionado) {
      return null;
    }

    return (
      <Alert
        action={
          <Button onClick={() => setModalImportacao(true)} color="inherit" variant="outlined">
            {isModeloUnico ? "IMPORTAR CALENDÁRIO" : "IMPORTAR MODELO"}
          </Button>
        }
        style={{ width: "100%", marginBottom: 10 }}
        severity="warning"
      >
        {isModeloUnico
          ? `Escola sem calendário importado para este ano.`
          : `Turnos sem modelos importados: ${turnosDisponiveis.map((turno) => turno.descricao)}`}
      </Alert>
    );
  };

  const visibleButtonHeader =
    ((modelosParaImportar?.dados?.length > 0 && isModeloUnico) ||
      (modelosParaImportar?.dados?.length > 0 && turnosDisponiveis.length > 0 && !isModeloUnico) ||
      (user.perfil === ROLES.PERFIL_GESTOR && modelos?.dados?.length === 0)) &&
    permiteAlteracaoViaAnoSelecionado;

  const renderSubtitle = () => {
    if (user.perfil === ROLES.PERFIL_GESTOR) {
      return user.nome;
    }

    if (user.perfil === ROLES.PERFIL_ESCOLA) {
      return `${user?.escola?.nome} (INEP: ${user?.escola?.inep})`;
    }

    return undefined;
  };

  const getGrid = () => {
    if (visibleButtonHeader) {
      return isModeloUnico ? 5 : 2.6;
    } else {
      return isModeloUnico ? 6 : 3;
    }
  };

  return (
    <>
      <AbortController />
      <Grid container style={{ width: "100%" }}>
        <Grid className="header-painel-calendario" item xs={12} justifyContent={"flex-end"}>
          <Header titulo={"Calendário"} subtitulo={renderSubtitle()} user={user}>
            <Grid item xs={12} sm={getGrid()} md={getGrid()}>
              {anos && anos.dados && (
                <FormControl fullWidth size="small">
                  <TextField
                    select
                    value={anoSelecionado}
                    label="Ano"
                    InputLabelProps={{ shrink: true, fontSize: "0.75rem" }}
                    onChange={(val) => alteraAnoSelecionadoCalendario(val)}
                    sx={{ background: "#fff" }}
                    size="small"
                    InputProps={{ style: { fontSize: "18px" } }}
                  >
                    {anos.dados.map((item, i) => {
                      return (
                        <MenuItem key={i} sx={{ fontSize: "18px" }} value={item.ano}>
                          {item.ano}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              )}
            </Grid>
            {user.perfil === ROLES.PERFIL_GESTOR && !isModeloUnico && (
              <Grid item xs={12} sm={visibleButtonHeader ? 2.6 : 3} md={visibleButtonHeader ? 2.6 : 3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Modelo</InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={modeloSelecionado ? modeloSelecionado : ""}
                    label="Modelo"
                    onChange={(val) => dispatch(alteraModeloSelecionado(val.target.value))}
                  >
                    {modelos &&
                      modelos.dados &&
                      modelos.dados.map((item, i) => {
                        return (
                          <MenuItem key={i} sx={{ fontSize: "18px" }} value={item.id}>
                            {item.descricao ? item.descricao : `Modelo ${item.modelo}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {user.perfil === ROLES.PERFIL_ESCOLA && !isModeloUnico && (
              <Grid item xs={12} md={visibleButtonHeader ? 2.6 : 3} sm={visibleButtonHeader ? 2.6 : 3}>
                <FormControl fullWidth size="small">
                  <InputLabel>Turno</InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={turnoSelecionado ? turnoSelecionado : ""}
                    label="Modelo"
                    onChange={(val) => dispatch(alteraTurnoSelecionado(val.target.value))}
                  >
                    {turnos &&
                      turnos.dados &&
                      turnos.dados.map((item, i) => {
                        return (
                          <MenuItem key={i} sx={{ fontSize: "18px" }} value={item.sigla}>
                            {item.descricao}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {visibleButtonHeader && (
              <Grid item xs={12} md={0.5} sm={0.2}>
                <Button
                  style={{ padding: "12px 0px" }}
                  variant="contained"
                  onClick={() =>
                    user.perfil === ROLES.PERFIL_ESCOLA ? setModalImportacao(true) : setModalNovoModelo(true)
                  }
                >
                  <FaPlus />
                </Button>
              </Grid>
            )}
          </Header>
        </Grid>
        <Grid item xs={12}>
          {modelos && <LoadingData obj={modelos} msg="Carregando modelos..." />}
          {descritores && <LoadingData obj={descritores} msg="Carregando descritores..." />}
          {fluxoStatus && <LoadingData obj={fluxoStatus} msg="Buscando lista de validações de status calendário..." />}
          {getAlertTurnosDisponiveis()}
        </Grid>
        {renderPainelOuMensagem()}
        {modalNovoModelo && (
          <ModalModeloFormulario
            open={true}
            close={() => setModalNovoModelo(false)}
            countModelos={modelos && modelos.dados ? modelos.dados[modelos.dados.length - 1]?.modelo : 0}
            anoSelecionado={anoSelecionado}
            user={user}
          />
        )}
        {modalImportacao && (
          <ModalImportacao
            open={true}
            isModeloUnico={isModeloUnico}
            close={() => setModalImportacao(false)}
            anoSelecionado={anoSelecionado}
            user={user}
            turnosDisponiveis={isModeloUnico ? turnos?.dados : turnosDisponiveis}
          />
        )}
      </Grid>
    </>
  );
};

export default Painel;
